import React from "react";
import Loadable from '@loadable/component';

import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
const Hero = Loadable(() => import(/* webpackChunkName: "HomeHero" */ '../components/homepage/heroSection'), {
  fallback: <div>loading...</div>
});
const Innovation = Loadable(() => import(/* webpackChunkName: "HomeInnovation" */ '../components/homepage/innovationSection'), {
  fallback: <div>loading...</div>
});
const Services = Loadable(() => import(/* webpackChunkName: "HomeServices" */ '../components/homepage/servicesSection'), {
  fallback: <div>loading...</div>
});
const Testimonial = Loadable(() => import(/* webpackChunkName: "HomeTestimonial" */ '../components/homepage/testimonialsSection'), {
  fallback: <div>loading...</div>
});
const Clients = Loadable(() => import(/* webpackChunkName: "HomeClients" */ '../components/homepage/clientsSection'), {
  fallback: <div>loading...</div>
});
const Locations = Loadable(() => import(/* webpackChunkName: "HomeLocations" */ '../components/common/locationSection'), {
  fallback: <div>loading...</div>
});

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="Clarity Innovations designs, develops, and deploys force-enhancing software that links human ingenuity to powerful computing and makes our country and our world a better, safer place." />
    <Hero />
    <Innovation />
    <Services />
    <Testimonial />
    <Clients />
    <Locations />
  </Layout>
);

export default IndexPage;
